import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createInstance, Piral, createStandardApi, createDashboardApi } from 'piral';
import { errors, structure } from './app/structure';
import { createBlazorApi } from 'piral-blazor';
import { createPageLayoutsApi } from 'piral-page-layouts';
import { createTrackingApi } from 'piral-tracking';
import { createTrackerApi } from 'piral-tracker';
import { createSearchApi } from 'piral-search';
import PageLayout from './app/parts/PageLayout';
import ModuleLayout from './app/parts/ModuleLayout';
import { OidcClient, createOidcApi, setupOidcClient } from 'piral-oidc';
import { startup } from './app/startup';
import './app/webcomponents';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      [key: string]: any;
    }
  }
}

const root = createRoot(document.querySelector('#app'));

let bootErrorHandler = () => {
  root.render(<span>Error during boot sequence</span>);
}

function start(appsettings: any, oidcClient: OidcClient) {
  const instance = createInstance({
    state: {
      components: structure,
      data: {
        appsettings: {
          owner: '',
          target: 'memory',
          expires: -1,
          value: appsettings
        },
        client: {
          owner: '',
          target: 'memory',
          expires: -1,
          value: oidcClient
        }
      },
      errorComponents: errors,
    },
    plugins: [...createStandardApi(), createBlazorApi(), createTrackingApi(), createTrackerApi(), createSearchApi(), createOidcApi(oidcClient), createPageLayoutsApi({
      fallback: 'module',
      layouts: {
        'module': ModuleLayout,
        'page': PageLayout
      }
    }), createDashboardApi({ routes: ["/dashboard"] })],
    requestPilets() {
      return appsettings?.feed
        ? oidcClient.token()
          .then(token => fetch(appsettings.feed))
          .then(res => res.json())
          .then(res => res.items)
          .catch(reject => Promise.resolve([]))
        : Promise.resolve([]);
    },
  });

  startup(instance);
  root.render(<Piral instance={instance}>
  </Piral>);
}

async function boot() {
  let appsettings = await fetch('/assets/appsettings.json').then(res => res.json()).catch(bootErrorHandler);
  await fetch('/assets/importmap.json')
    .then(res => res.text())
    .then(value => {
      const im = document.createElement('script');
      im.type = 'importmap';
      im.textContent = value;
      document.querySelector('script').after(im);
    })
    .then(_ => {
      const im = document.createElement('script');
      im.src = '/assets/importmap.js';
      document.querySelector('script').after(im);
    })
    .catch(bootErrorHandler);

  let oidcClient = setupOidcClient({
    clientId: appsettings.clientId,
    responseType: 'code',
    identityProviderUri: appsettings.authority,
    redirectUri: `${location.origin}/signincallback`,
    scopes: ['openid', 'profile', 'offline_access'],
    signInRedirectParams: {
      state: {
        originalPath: `${location.pathname}${location.search}`
      }
    },
    postLogoutRedirectUri: `${location.origin}/signoutcallback`
  });

  if (location.pathname === '/signoutcallback' || location.pathname === '/signout' || location.pathname === '/devstart') {
    start(appsettings, oidcClient);
    return;
  }

  oidcClient.handleAuthentication()
    .then(resp => {
      if (resp.state?.originalPath) {
        location.href = resp.state.originalPath;
      } else if (resp.shouldRender) {
        oidcClient._.startSilentRenew();
        oidcClient._._events.addSilentRenewError((e: any) => {
          console.log('Silent renew for refresh token failed');
        });
        start(appsettings, oidcClient);
      }
    })
    .catch(err => {
      oidcClient.logout();
    });
}

boot();